.fouthStepRequest-toggle {
  margin-bottom: 20px;
}
.fouthStepRequest-containerRepresentative {
  margin: 20px 0 13px 0;
  .firstRepresentativeCard-delete {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    cursor: pointer;
  }
}

.header-company-container {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  .header-company-row {
    .select-col-company .select-input {
      width: 180px;
    }

    .btn-add-circle {
      width: 180px;
    }
  }

  .firstRepresentativeCard-delete {
    padding-top: 0;
    margin-left: 20px;
  }
}

.dropdown-company {
  .ant-select-item {
    background-color: #ffffff;
  }
}

.btn-delete-company {
  justify-content: flex-end;
  display: flex;
  padding-right: 25px;
  .firstRepresentativeCard-delete {
    cursor: pointer;
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;