@import './../../../resources/styles/breakpoints.less';
.naturalRepresentative-container {
  .headerCard-row {
    justify-content: space-between;
    margin-bottom: 8px !important;
    gap: 8px !important;
    .header-company-container {
      flex: 1 0 360px;
    }
  }
  .representativeCard-row {
    gap: 8px !important;
    .ant-select-selection-placeholder {
      color: #002452;
      opacity: 0.5;
      font-size: 14px;
    }

    .media-breakpoint-max({
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .two-columns{
          grid-column: span 2;
      }
    });
  }
}

@primary-color: #002452;@box-shadow-base: 0px 5px 15px rgba(83, 80, 77, 0.15);@border-radius-base: 8px;@input-height-base: 45px;@text-color: #828282;@text-color-secondary: #888888;